import Experiments from '@wix/wix-experiments';
import { PETRI_BASE_URL, PETRI_SCOPE } from '../constants';

let experiments: Experiments;

export const isExperimentEnabled = async (experiment: string) => {
  if (!experiments) {
    await conduct();
  }
  return experiments.get(experiment) === 'true';
};

const conduct = () => {
  experiments = new Experiments({ baseUrl: PETRI_BASE_URL });
  const viewerScriptScope = experiments.load(PETRI_SCOPE);
  const uouMultiLocationScope = experiments.conduct(
    'specs.bookings.UoUMultiLocationV1',
    'false',
  );
  const osMultiLocationScope = experiments.conduct(
    'specs.wossm.EnableMultiLocation',
    'false',
  );
  const proGalleryScope = experiments.conduct(
    'specs.bookings.OwnerGallery',
    'false',
  );
  return Promise.all([
    viewerScriptScope,
    uouMultiLocationScope,
    osMultiLocationScope,
    proGalleryScope,
  ]);
};
