import { addBookingsPage } from '../pages-actions';
import { MigrationFailReason, PageId } from '../../constants';
import {
  getAllSiteComponents,
  getFullComponentStructure,
  updateComponentStyle,
  updateControllerConfiguration,
} from '../editor-sdk-actions';
import { ComponentStructure, ComponentType } from '../../migration/domain';
import { getNewServicePageStyles } from './styles-transpiler';
import { getNewServicePageData } from './settings-data-transpiler';
import { EditorScriptApi } from '../../api/api';

export async function migrate(
  sdk,
  appToken,
  translations,
  onNextStep,
  editorScriptApi: EditorScriptApi,
) {
  let currentStep = 1;
  onNextStep(currentStep++);
  try {
    await addBookingsPage(PageId.BOOKINGS_SERVICE_PAGE, sdk, appToken);
  } catch {
    throw MigrationFailReason.PAGES;
  }
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await Promise.all(
    allComponents.map((componentRef) =>
      getFullComponentStructure(sdk, appToken, componentRef),
    ),
  );
  const bookingsCheckoutWidgetId = '713f72a2-7a32-47e9-b5e4-6024ee57d277';
  const servicePageWidgetId = 'a91a0543-d4bd-4e6b-b315-9410aa27bcde';
  const compStructuresCheckout: ComponentStructure = siteStructure.find(
    (comp) =>
      comp.type === ComponentType.COMPONENT &&
      comp.data?.widgetId === bookingsCheckoutWidgetId,
  );
  const compStructuresServicePage: ComponentStructure = siteStructure.find(
    (comp) =>
      comp.type === ComponentType.COMPONENT &&
      comp.data?.widgetId === servicePageWidgetId,
  );
  if (compStructuresCheckout.style?.style?.properties) {
    console.log('Old Service Page Properties:');
    console.log(compStructuresCheckout);
    console.table(compStructuresCheckout.style.style.properties);
    console.log('New Service Page structure: (Before Change)');
    console.log(compStructuresServicePage);
    console.table(compStructuresServicePage.style?.style?.properties);
    const newServicePageData = getNewServicePageData(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Layouts to write:');
    console.table(newServicePageData);
    onNextStep(currentStep++);
    if (newServicePageData) {
      try {
        await updateControllerConfiguration(
          sdk,
          appToken,
          compStructuresServicePage.id,
          newServicePageData,
        );
      } catch {
        throw MigrationFailReason.DATA;
      }
    }
    const newServicePageStyles = getNewServicePageStyles(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Styles to write:');
    console.table(newServicePageStyles);
    onNextStep(currentStep++);
    if (newServicePageStyles) {
      try {
        await updateComponentStyle(
          sdk,
          appToken,
          compStructuresServicePage.id,
          newServicePageStyles,
        );
      } catch {
        throw MigrationFailReason.STYLES;
      }
    }
  } else {
    console.log('Old service page is plain, no styles to migrate.');
  }

  try {
    await editorScriptApi.migrateMediaGallery();
  } catch {
    throw MigrationFailReason.DATA;
  }
}
